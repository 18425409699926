<template>
  <main>
    <div class="form status-form">
      <div class="breadcumb">
        <router-link :to="`/status/${submission_id}/${uid}`"
          >Mon statut</router-link
        >
        > Je déclare mes produits ou services Husqvarna
      </div>
      <Form
        id="form"
        :form_id="form_id"
        v-on:form_submit_loading="loading"
        :submission_id="submission_id"
        :server="server"
        @on_error="viewError"
         :hideError="hiderror"
        v-on:results="getResult"
      />
      <div class="captcha">
        This site is protected by reCAPTCHA and the Google<br />
        <a href="https://policies.google.com/privacy" target="_blank"
          >Privacy Policy</a
        >
        and
        <a href="https://policies.google.com/terms" target="_blank"
          >Terms of Service</a
        >
        apply.
      </div>
    </div>
  </main>
</template>
<script>
let consts = require("../config");

export default {
  name: "Status",
  data: function() {
    return {
      hiderror: true,
      uid: this.$route.params._secu,
      submission_id: this.$route.params._id,
      form_id: consts.LINE_ITEM_ID,
      server: "https://api.lucien.ai",
    };
  },
  methods: {
    loading(value) {
      if (value === true) {
        this.$swal({
          title: "Envoi en cours",
          html: "Merci de bien vouloir patienter ...",
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
      }
    },
    getResult(result) {

      if (result._id) {
        this.$swal({
          type: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          html: "<b>Votre document à bien été pris en compte. Il sera vérifié rapidement.</b>",
        }).then((result) => {
          this.$router.push(
            "/status/" + this.$route.params._id + "/" + this.$route.params._secu
          );
        });
      } else {
        this.$swal({
          type: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          text: "Une erreur est survenue",
        });
      }
    },
   viewError(err) {

          var text_error;
          if(err[0] == 'prix_produits is required')  text_error = 'Le prix du produit est requis'
          else if(err[0] == 'Email domain not allowed')   text_error = 'Le prix du produit est requis'
          else  text_error = err  

          this.$swal({
            confirmButtonText: 'OK',
            confirmButtonColor: '#C94330',
            text: text_error
          });
        
    },      
  },
};
</script>
